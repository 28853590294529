// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star {
  color: red !important;
}
.billing-from-title {
  font-size: 20px;
  font-weight: 500;
  margin: 5px 30px 35px !important;
}
.active-color {
  color: green;
}
.cancle-color {
  color: red;
}
.cancle-payment {
  margin-top: 25px;
  font-size: 24px;
}
.ant-col-button {
  display: flex;
  margin-top: 25px !important;
}
.close-button {
  margin: 0 15px 15px 0px !important;
}
.cancled-text {
  color: red;
  margin-bottom: 5px;
  word-wrap: break-word;
  /* Ensures long words wrap to the next line */
  word-break: break-word;
  /* Handles wrapping for long words */
  white-space: normal;
  /* Ensures normal text wrapping */
}
.row-reverser {
  display: flex !important;
  flex-direction: row-reverse !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/BillingScreen/billing.less"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gCAAgC;AAClC;AACA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,kCAAkC;AACpC;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,qBAAqB;EACrB,6CAA6C;EAC7C,sBAAsB;EACtB,oCAAoC;EACpC,mBAAmB;EACnB,iCAAiC;AACnC;AACA;EACE,wBAAwB;EACxB,sCAAsC;AACxC","sourcesContent":[".star {\n  color: red !important;\n}\n.billing-from-title {\n  font-size: 20px;\n  font-weight: 500;\n  margin: 5px 30px 35px !important;\n}\n.active-color {\n  color: green;\n}\n.cancle-color {\n  color: red;\n}\n.cancle-payment {\n  margin-top: 25px;\n  font-size: 24px;\n}\n.ant-col-button {\n  display: flex;\n  margin-top: 25px !important;\n}\n.close-button {\n  margin: 0 15px 15px 0px !important;\n}\n.cancled-text {\n  color: red;\n  margin-bottom: 5px;\n  word-wrap: break-word;\n  /* Ensures long words wrap to the next line */\n  word-break: break-word;\n  /* Handles wrapping for long words */\n  white-space: normal;\n  /* Ensures normal text wrapping */\n}\n.row-reverser {\n  display: flex !important;\n  flex-direction: row-reverse !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
