import React, { useState } from "react";
import ChatBot from "react-chatbotify";
import {
  addPatientAppointmentEffect,
  doctorConsultationFeeEffect,
  getDoctorListEffect,
  getPatientByIdEffect,
  getpatientListAppointmentEffect,
  getscheduleListEffect,
} from "../../appRedux/Patient/Sagas/Effect/patientAppointmentEffect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { currentDate } from "../../util/utilz";
import {
  getReasonListInprogress,
  getSymptomListInprogress,
} from "../../appRedux/admin/Doctor/actions/Doctors";

const tooltip = {
  mode: "CLOSE",
  text: "Book Your Appointments! 😊",
};
const role_id = Number(localStorage.getItem("role_id"));
function ChatBots() {
  const [form, setForm] = useState({});
  const [options, setOptions] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [doctorConsulations, setDoctorConsulations] = useState([]);
  const [doctorSlot, setDoctorSlot] = useState([]);
  const [reasonListData, setReasonListData] = useState([]);
  const [symptomListData, setSymptomListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otherReason, setOtherReason] = useState(false);
  const [otherSymptom, setOtherSymptom] = useState(false);
  const dispatch = useDispatch();
  const { reasonList, symptomList } = useSelector((state) => state.doctor);
  const formStyle = {
    marginTop: 10,
    marginLeft: 20,
    border: "1px solid #491d8d",
    padding: 10,
    borderRadius: 5,
    maxWidth: 300,
  };

  useEffect(() => {
    const payload = { pagination: "false" };
    getStatusList();
    getDoctorList();
    dispatch(getReasonListInprogress(payload));
    dispatch(getSymptomListInprogress(payload));
  }, []);

  const getDoctorList = async () => {
    try {
      const { data } = await getDoctorListEffect();
      const doctorList = data?.data;
      let options = doctorList.map((row) => ({
        display: `${row.firstName} - ${row.Doctor_serial_no}`,
        value: row.id,
      }));
      setDoctor(options);
    } catch (error) {
      setDoctor([]);
    }
  };

  const getStatusList = async () => {
    try {
      setFamilyMembers([]);
      const { data } = await getpatientListAppointmentEffect();
      const patientList = data?.data;
      let options = patientList.map((row) => ({
        display: `${row.firstName} ${
          row.patient_serial_no ? row.patient_serial_no : ""
        }`,
        value: row.id,
      }));
      setOptions(options);
    } catch (error) {
      setOptions([]);
      setFamilyMembers([]);
    }
  };
  useEffect(() => {
    let option = reasonList?.data?.map((row) => ({
      display: row?.name,
      value: row.id,
    }));
    setReasonListData(option);
    let symptomOption = symptomList?.data?.map((row) => ({
      display: row?.name,
      value: row.id,
    }));
    setSymptomListData(symptomOption);
  }, [reasonList, symptomList]);
  console.log("reasonIIIII", otherReason);
  const flow = {
    start: {
      message: "Hello there! Do You want to book a appointment here?",
      function: (params) => setForm({ name: params.userInput }),
      options: ["Yes", "No"],
      chatDisabled: true,
      path: async (params) => {
        setForm({});
        if (params.userInput === "Yes") {
          return "ask_patient";
        }
        return "end2";
      },
    },
    ask_patient: {
      message: "Enter Patient ID / Mobile",
      // function: (params) => {
      //   const selectedOption = options.find(
      //     (opt) => opt.display === params.userInput
      //   );
      //   if (selectedOption) {
      //     setForm({
      //       ...form,
      //       patientName: selectedOption.display,
      //       patient_id: selectedOption.value,
      //     });
      //   }
      // },
      // options: options.map((opt) => opt.display),
      // chatDisabled: options.length != 0,
      path: async (params) => {
        try {
          // const selectedOption = await options.find(
          //   (opt) => opt.display === params.userInput
          // ).value;
          setFamilyMembers([]);
          const { data } = await getPatientByIdEffect(params.userInput);
          const doctorList = data?.data?.User?.FamilyMembers;
          let option = doctorList.map((row) => ({
            display: `${row.firstName} - ${
              row.relationshipName ? row.relationshipName : "Brother"
            }`,
            value: row.id,
          }));
          setForm({
            ...form,
            patientName: data?.data?.firstName,
            patient_id: data?.data?.id,
            patientMobile: data?.data?.contact,
            patient_serial_no: data?.data?.patient_serial_no,
          });
          setFamilyMembers(option);

          return "ask_patient_family_member";
        } catch (error) {
          if (error?.response?.data?.message === "Patient not found") {
            await params.injectMessage("Patient is not available kindly add");
          } else {
            await params.injectMessage(error?.response?.data?.message);
          }
          setFamilyMembers([]);
          return "end2";
        }
      },
    },
    ask_patient_family_member: {
      message: "Please Select Your Family Memeber",
      function: (params) => {
        const selectedOption = familyMembers.find(
          (opt) => opt.display === params.userInput
        );
        if (selectedOption) {
          setForm({
            ...form,
            familyMember: selectedOption.display,
            family_member_id: selectedOption.value,
          });
        }
      },
      options: familyMembers.map((opt) => opt.display),
      chatDisabled: familyMembers.length !== 0,
      path: async (params) => {
        if (params) {
          return "ask_doctor";
        }
        return "end2";
      },
    },

    ask_doctor: {
      message: "Please Select Your Doctor",
      function: (params) => {
        const selectedOption = doctor.find(
          (opt) => opt.display === params.userInput
        );

        if (selectedOption) {
          setForm({
            ...form,
            DoctorName: selectedOption.display,
            doctor_id: selectedOption.value || params.userInput,
          });
        }
      },
      options: doctor.map((opt) => opt.display),
      chatDisabled: doctor.length != 0,
      path: async (params) => {
        try {
          const selectedOption = await doctor.find(
            (opt) => opt.display === params.userInput
          ).value;

          setDoctorConsulations([]);
          const { data } = await doctorConsultationFeeEffect(selectedOption);
          const doctorList = data?.data;
          const tele = doctorList?.tele_consultation_fee.toString();
          const pers = doctorList?.inperson_consultation_fee.toString();
          const consultationList = [
            {
              id: pers,
              display: "InPerson Consultation" + "    --    " + pers,
              value: "1",
            },
            {
              id: tele,
              display: "Tele Consultation" + "       --      " + tele,
              value: "2",
            },
          ];
          setDoctorConsulations(consultationList);
          return "ask_consultaion";
        } catch (error) {
          setDoctorConsulations([]);
          await params.injectMessage("Please try agin later");
          return "end2";
        }
      },
    },
    ask_consultaion: {
      message: "Select your consulations Type",
      function: (params) => {
        const selectedOption = doctorConsulations.find(
          (opt) => opt.display === params.userInput
        );
        if (selectedOption) {
          setForm({
            ...form,
            Consulations: selectedOption.display,
            consulation_type: selectedOption.value,
          });
        }
      },
      options: doctorConsulations.map((opt) => opt.display),
      chatDisabled: doctorConsulations.length != 0,
      path: async (params) => {
        return "ask_date";
      },
    },
    ask_date: {
      message: "Select your Date",
      function: (params) => {
        setForm({
          ...form,
          date: params.userInput,
        });
      },
      options: [
        moment().format("YYYY-MM-DD"),
        moment().add(1, "days").format("YYYY-MM-DD"),
        moment().add(2, "days").format("YYYY-MM-DD"),
        "skip",
      ],
      chatDisabled: true,
      path: async (params) => {
        try {
          if (params.userInput === "skip") {
            return "end2";
          } else {
            setDoctorSlot([]);
            const payload = {
              date: params.userInput,
              doctor_id: form?.doctor_id,
              currentDate,
              currentTime: moment().format("hh:mm A"),
              familyMemberId: form.family_member_id,
            };
            const { data } = await getscheduleListEffect(payload);
            const doctorList = data?.data;
            let option = doctorList.map((row) => ({
              display: `${moment(row?.start_time, ["h:mm A"]).format(
                "h:mm A"
              )}`,
              value: row.id,
            }));
            setDoctorSlot(option);
            if (doctorList.length != 0) {
              return "ask_slot";
            } else {
              await params.injectMessage(
                "They is no slot are avabile this day, Please try different day"
              );
              return "ask_date";
            }
          }
        } catch (error) {
          setDoctorSlot([]);
          return "end2";
        }
      },
    },
    ask_slot: {
      message: "Select the slot",
      function: (params) => {
        const selectedOption = doctorSlot.find(
          (opt) => opt.display === params.userInput
        );
        if (selectedOption) {
          setForm({
            ...form,
            slot: selectedOption.display,
            slot_id: selectedOption.value,
          });
        }
      },
      options: doctorSlot.map((opt) => opt.display),
      chatDisabled: doctorSlot.length != 0,
      path: async (params) => {
        return "ask_reason";
      },
    },
    // ask_reason: {
    //   message: "Select the reason",
    //   function: (params) => {
    //     const selectedOption = reasonListData.find(
    //       (opt) => opt.display === params.userInput
    //     );
    //     if (selectedOption) {
    //       setForm({
    //         ...form,
    //         slot: selectedOption.display,
    //         slot_id: selectedOption.value,
    //       });
    //     }
    //   },
    //   options: reasonListData.map((opt) => opt.display),
    //   chatDisabled: reasonListData.length != 0,
    //   path: async (params) => {
    //     return "ask_symptoms";
    //   },
    // },

    // ask_symptoms: {
    //   message: "Select the symptom",
    //   function: (params) => {
    //     const selectedOption = symptomListData.find(
    //       (opt) => opt.display === params.userInput
    //     );
    //     if (selectedOption) {
    //       setForm({
    //         ...form,
    //         symptom: selectedOption.display,
    //         symptom_id: selectedOption.value,
    //       });
    //     }
    //   },
    //   options: symptomListData.map((opt) => opt.display),
    //   chatDisabled: symptomListData.length != 0,
    //   path: async (params) => {
    //     return "end";
    //   },
    // },

    ask_reason: {
      message: "Select the reason",
      function: (params) => {
        if (params.userInput === "Others") {
          const selectedOption = reasonListData.find(
            (opt) => opt.display === params.userInput
          );

          setForm({ ...form, reason: "" });
          setOtherReason(true);
        } else {
          setOtherReason(false);
          const selectedOption = reasonListData.find(
            (opt) => opt.display === params.userInput
          );
          if (selectedOption) {
            setForm({
              ...form,
              reason: selectedOption.display,
              reason_id: selectedOption.value,
            });
          }
        }
      },
      options: [...reasonListData.map((opt) => opt.display), "Others"],
      chatDisabled: reasonListData.length != 0,

      path: async (params) => {
        if (params.userInput === "Others") {
          return "ask_custom_reason";
        }
        return "ask_symptoms";
      },
    },

    ask_custom_reason: {
      message: "Please enter your  reason",
      function: (params) => {
        setForm({ ...form, otherReason: params.userInput });
      },
      chatDisabled: false,
      path: async () => "ask_symptoms",
    },

    ask_symptoms: {
      message: "Select the symptom",
      function: (params) => {
        if (params.userInput === "Others") {
          setForm({ ...form, symptom: "" });
          setOtherSymptom(true);
        } else {
          setOtherSymptom(false);
          const selectedOption = symptomListData.find(
            (opt) => opt.display === params.userInput
          );

          if (selectedOption) {
            setForm({
              ...form,
              symptom: selectedOption.display,
              symptom_id: selectedOption.value,
            });
            if(selectedOption.value){
              setOtherSymptom(selectedOption.value);
            }
           
          }
        }
      },
      options: [...symptomListData.map((opt) => opt.display), "Others"],
      chatDisabled: symptomListData.length != 0,
      path: async (params) => {
        if (params.userInput === "Others") {
          return "ask_custom_symptom";
        }
        return "end";
      },
    },

    ask_custom_symptom: {
      message: "Please enter your  symptom",
      function: (params) => {
        setForm({ ...form, otherSymptom: params.userInput });
      },
      chatDisabled: false,
      path: async () => "end",
    },

    end: {
      message: "Thank you for your interest",
      render: (
        <div style={formStyle}>
          <p>
            Patient ID/Mobile: {form.patient_serial_no} /{form.patientMobile}{" "}
          </p>
          <p>Patient Name: {form.patientName}</p>
          <p>Doctor Name: {form.DoctorName}</p>
          <p>Family: {form.familyMember}</p>
          <p>Consulations: {form.Consulations}</p>
          <p>Date: {form.date}</p>
          <p>Time: {form.slot}</p>
          <p>Reason: {otherReason ? form.otherReason : form.reason}</p>
          <p>Symptoms: {otherSymptom ? form.otherSymptom : form.symptom}</p>
        </div>
      ),
      options: ["Confirm Here", "Cancel", "Again Book Appointment"],
      chatDisabled: true,
      path: async (params) => {
        setForm({});
        if (params.userInput === "Confirm Here") {
          try {
            const symptom = otherSymptom ? form.otherSymptom : (form.symptom === true ? form.symptom : false);

            const payload = {
              consultation_type: form.consulation_type,
              date: form.date,
              doctor_id: form.doctor_id,
              family_member_id: form.family_member_id,
              patient_id: form.patient_id,
              disease_id: "",
              qusetion_answer: [],
              slot_id: form.slot_id,
              reason: otherReason ? form.otherReason : form.reason,
              symptom: otherSymptom ===true? form.otherSymptom : form.symptom,
              organization_id: localStorage.getItem("org_id"),
            };

            const { data } = await addPatientAppointmentEffect(payload);

            if (data) {
              await params.injectMessage(data?.message);
              return "conformations";
            } else {
              return "ask_error";
            }
          } catch (error) {
            await params.injectMessage(
              "They is no slot are avabile this day, Please try different day"
            );
            return "ask_error";
          }
        } else if (params.userInput === "Again Book Appointment") {
          return "ask_patient";
        } else {
          return "end2";
        }
      },
    },
    conformations: {
      message: "Thank you for your interest! You Appointment has been booked ",
      options: ["Book Appointment"],
      chatDisabled: true,
      path: "start",
    },
    end2: {
      message: "Thank you for your interest!",
      options: ["Book Appointment"],
      chatDisabled: true,
      path: "start",
    },
    ask_error: {
      message: "Thank you for your interest! Please try agin later",
      options: ["Book Appointment"],
      chatDisabled: true,
      path: "start",
    },
  };

  const clearMessages = () => {
    setForm({});
  };
  return (
    <ChatBot
      options={{
        tooltip: tooltip,
        header: {
          title: (
            <>
              {" "}
              <h3 style={{ cursor: "pointer", margin: 0 }}>Appointments</h3>
            </>
          ),
          showAvatar: true,
          //   closeChatIcon: clearMessages,
        },
        chatHistory: {
          disabled: true,
        },
        botOptionStyle: {
          fontSize: "12px",
          padding: "10px 15px",
        },
        botOptionHoveredStyle: {
          fontSize: "12px",
          padding: "10px 15px",
        },
        notification: false,
        footer: {
          text: (
            <div style={{ cursor: "pointer" }}>
              <span>Powered By </span>
              <span style={{ fontWeight: "bold" }}>
                {/* <img style={{ width: 10, height: 10 }} src={logo}></img> */}
                <span> Yaa Creations</span>
              </span>
            </div>
          ),
        },
        fileAttachment: false,
        emoji: false,
        sendOptionOutput: { options: false },
      }}
      flow={flow}
    />
  );
}

export default ChatBots;
