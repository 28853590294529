import React, { useRef } from "react";
import "./billing.less";
import {
  Modal,
  Form,
  Input,
  Button,
  Col,
  Select,
  Typography,
  Spin,
} from "antd";
import { addPaymentInProgress } from "../../appRedux/doctor/actions/addPayment";
import { useDispatch } from "react-redux";
import moment from "moment";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import FormInput from "../../components/Input/FormInput";
import { disablePastDate } from "../../util/utilz";
import { useSelector } from "react-redux";
const { Option } = Select;

const CollectPaymentModal = ({
  isVisible,
  onCancel,
  onSubmit,
  selectedBillingId,
  setNavigate,
}) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  let DATE = moment().format("YYYY-MM-DD");
  const { billingPaymentreducer } = useSelector((state) => state);
  const handleFinish = () => {
    formRef.current
      .validateFields()
      .then((values) => {
        const paylod = {
          ...values,
          Date: values?.Date ? values?.Date : DATE,
          paid_amount: Number(values?.paid_amount),
          billing_id: selectedBillingId,
          onSubmit,
        };
        setNavigate(true);
        dispatch(addPaymentInProgress(paylod));
      })

      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  const depositType = [
    { key: 1, value: "cash" },
    { key: 3, value: "online" },
    { key: 2, value: "cheque" },
    { key: 4, value: "card" },
  ];

  return (
    <Spin spinning={billingPaymentreducer?.addPayments?.inProgress === true}>
      <Modal
        title="Collect Payment"
        visible={isVisible}
        width={800}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleFinish}>
            Submit
          </Button>,
        ]}
        className={isVisible ? "slide-down" : ""}
      >
        <Form name="collectPaymentForm" ref={formRef}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <Typography>
                Amount<span className="star">*</span>
              </Typography>
              <Form.Item
                name="paid_amount"
                // rules={[
                //   { required: true, message: "Please input the amount!" },
                // ]}
              >
                <FormInput
                  type="number"
                  name="paid_amount"
                  rules={[
                    {
                      required: true,
                      message: "Enter the  Amount",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <Typography>
                Date<span className="star">*</span>
              </Typography>
              <Form.Item name="Date">
                <FormInput
                  type="date"
                  placeholder="date"
                  name="Date"
                  min={disablePastDate()}
                  defaultValue={DATE}
                />
              </Form.Item>
            </Col>
          </div>

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <Typography>
                Payment Type<span className="star">*</span>
              </Typography>
              <Form.Item
                name="Deposit_type"
                rules={[
                  {
                    required: true,
                    message: "Please select the payment method!",
                  },
                ]}
              >
                <FormSelectBox
                  options={depositType}
                  placeholder="Select"
                  className="sel"
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <Typography>
                Type<span className="star">*</span>
              </Typography>
              <Form.Item
                name="Type"
                rules={[{ required: true, message: "Please select the Type!" }]}
              >
                <Select>
                  <Option value="Due">Due</Option>
                  <Option value="Adjustment">Adjustment</Option>
                </Select>
              </Form.Item>
            </Col>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Col xs={23}>
              <Typography>Remarks</Typography>
              <Form.Item name="remarks">
                <Input />
              </Form.Item>
            </Col>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default CollectPaymentModal;
